<template>
  <div class="navbarmobileFix">
    <section class="banner-two2 img curve">
      <div class="containerfluid text-center text-align">
        <div class="doctorPageFirstSection">
          <div class="doctor-firstSectionText mt-4 d-none d-lg-block">
            <h1 class="">Grow Your Practice and <span  @click="yourPractice()"><a  href="#yourpractice"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" style="font-size: 1.6vw;color: #000;" /></a></span> 
          </h1>
            <h1 class="">Offer Informed Consultation <span @click="informationConsultation()"><a  href="#informationconsultaiton"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" style="font-size: 1.6vw;color: #000;" /></a> </span></h1>
            <h1 class=" line-txt">With the RxIx Practice Management App</h1>
          </div>

          <div class="doctorfirstSectionIamge d-none d-lg-block">
            <img class="img-doctor" src="~@/assets/images/nurse-img.png" />
          </div>
        </div>
      </div>
    </section>


    <!-- first section only for mobile view -->
    <div class="doctorPageFirstSection">
      <div class="doctor-firstSectionText mt-4 mb-4 text-align text-center container d-block d-lg-none">
        <h1 class="line-txt">Grow Your Practice </h1>
        <h1 >And <span  @click="yourPractice()"><a  href="#yourpractice"> 
            <font-awesome-icon :icon="['fas', 'question-circle']" class="questions-icon"   /></a></span></h1>
        <h1 class="line-txt">Offer Informed</h1>
        <h1>Consultation <span @click="informationConsultation()"><a  href="#informationconsultaiton"> 
            <font-awesome-icon :icon="['fas', 'question-circle']"  class="questions-icon" /></a> </span></h1>
        <h1 class="line-txt">With the</h1>
        <h1>RxIx Practice </h1>
        <h1>Management App</h1>
      </div>
    </div>

    <section class="container">
      <div class="text-align text-center">
        <div id="yourpractice" class="overlay"  v-show="showGrowYourPractice">
        <div class="popup">
          <div class="popupbox" >
            <p class="closeButton mt-0 " style="text-align: end;" @click="closeYourPractice()"><font-awesome-icon :icon="['fas','times']" /></p>         
    <!-- RxIx Support Message and Images for desktop - visible only in desktop pixel-->
    <div class="d-none d-lg-block">
        <fieldset class="RxixSupportToDoctor " >
          <legend> Grow your practice</legend>
          <div class="sliderMobileView">
            <img src="~@/assets/images/doctor-from-mobile.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-4">Join RxIx's patient groups and make your presence felt among
              the
              relevant patient community.</p>
          </div>

          <div class="sliderMobileView">
            <img src="~@/assets/images/well-communication.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-5">Get consultation request - clinic/ home/ tele/ second
              opinion -directly from
              RxIx patients.
            </p>
          </div>

          <div class="sliderMobileView">
            <img src="~@/assets/images/brain.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-6">Enhance your visibility. Publish your wisdom on RxIx. Your
              post
              reaches
              RxIx patients.</p>
          </div>
        </fieldset>
      </div>
    <!-- RxIx Support Message and Images for mobile and tablet - visible only in mobile and tablet pixel-->
      <div class="d-block d-lg-none">
        <div class="mobileborder ">
          <div class="py-2">
              <h3>Grow your practice</h3>
            </div>
          
    <div id="growyourpractice" class="carousel slide" data-bs-ride="carousel">
  
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      <img src="~@/assets/images/doctor-from-mobile.jpg" class="img-carousel-doctor">
      <p class="img-text-carousel-doctor-4">Join RxIx's patient groups and make your presence felt among
              the
              relevant patient community.</p>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="~@/assets/images/well-communication.jpg" class="img-carousel-doctor">
      <p class="img-text-carousel-doctor-5">Get consultation request - clinic/ home/ tele/ second
              opinion -directly from
              RxIx patients.
            </p>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="~@/assets/images/brain.jpg" class="img-carousel-doctor">
      <p class="img-text-carousel-doctor-6">Enhance your visibility. Publish your wisdom on RxIx. Your
              post
              reaches
              RxIx patients.</p>
    </div>
  </div>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#growyourpractice" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#growyourpractice" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#growyourpractice" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
</div>
</div>
</div>
        </div>
        </div>
        </div>
        <div id="informationconsultaiton" class="overlay"  v-show="showInformedConsultation">
        <div class="popup">
          <div class="popupbox" >
            <p class="closeButton mt-0 " style="text-align: end;" @click="closeInformationConsultation()"><font-awesome-icon :icon="['fas','times']" /></p>         
          <div class="d-none d-lg-block">
        <fieldset class="RxixSupportToDoctor" >
          <legend>Offer informed
              consultation</legend>
          <div class="sliderMobileView">
            <img src="~@/assets/images/disease-history-image.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-1">Access patient's past records, including case sheets of
              consultations
              with other doctors.</p>
          </div>
          <div class="sliderMobileView">
            <img src="~@/assets/images/educated-doctor.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-2">Access a library of clinical resources, including decision
              charts and
              standard treatment workflows.
            </p>
          </div>
          <div class="sliderMobileView">
            <img src="~@/assets/images/doctor-confrence.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-3">Tap into the knowlege and experience of fellow RxIx doctors.
              Share case
              informations and get their
              inputs.</p>
          </div>

        </fieldset>
        </div>
        <div class="d-block d-lg-none">
          <div class="mobileborder d-block d-lg-none">
          <div class="py-2">
              <h3>Offer informed consultation</h3>
            </div>
    <div id="information" class="carousel slide" data-bs-ride="carousel">
  
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="5000">
      <img src="~@/assets/images/disease-history-image.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-1">Access patient's past records, including case sheets of
              consultations
              with other doctors.</p>
    </div>
    <div class="carousel-item" data-bs-interval="5000" >
      <img src="~@/assets/images/educated-doctor.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-2">Access a library of clinical resources, including decision
              charts and
              standard treatment workflows.
            </p>
    </div>
    <div class="carousel-item" data-bs-interval="5000">
      <img src="~@/assets/images/doctor-confrence.jpg" class="img-carousel-doctor">
            <p class="img-text-carousel-doctor-3">Tap into the knowlege and experience of fellow RxIx doctors.
              Share case
              informations and get their
              inputs.</p>
    </div>
  </div>
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#information" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#information" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#information" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
</div>
</div>
</div>
      </div>
        </div>
        </div>
      </div>


    </section>

    <div class="cards-banner-doctor">
  

  <section class="banner-two3 img">
     <div class="container  text-center-cards">
       <h1 class="mb-0">To signup, select a feature <br>that appeals most to you</h1>
       <div class=" features row mt-4 row-cols-1 row-cols-md-3  text-center ">
         
         <div class="col ">
           <div class="card h-100 w-100" @click="patientgroupDoctor">
             <img class="icon-img1" src="~@/assets/images/patientgroup.png" />

             <div class="card-body" >
               <h5 class="card-title text-bold">Patients groups</h5>
               <p class="card-text">Join {{ this.practiceGroupCount }} groups, across
                diverse conditions or disorders</p>
             </div>
           </div>
         </div>
         <div class="col ">
           <div class="card h-100 w-100" @click="receiveconsultation">
             <img class="icon-img1" src="~@/assets/images1/doctor-hospital.png" />

             <div class="card-body" >
               <h5 class="card-title"> Consultation requests</h5>
               <p class="card-text">  Receive clinic and home consultation requests without any referral fee
               doubts.</p>
             </div>
           </div>
         </div>
         <div class="col">
           <div class="card h-100 w-100"  @click="doctorcommunities">
             <img class="icon-img1" src="~@/assets/images/one-one.png" />

             <div class="card-body">
               <h5 class="card-title"> Case inputs from fellow doctors</h5>
               <p class="card-text"> Join groups of doctors; get your queries resolved; offer improved
                consultation</p>
             </div>
           </div>
         </div>
       </div>
       <div class="row features mt-5  row-cols-1 row-cols-md-3 ">

         <div class="col">
           <div class="card h-100 w-100 " @click="offerconsultation">
             <img class="icon-img1" src="~@/assets/images/meet-icon.png" />


             <div class="card-body">
               <h5 class="card-title">Digital consultation records</h5>
               <p class="card-text">  Create digital records with just your mobile and regular hand-written prescription pad
        </p>
      </div>
           </div>
         </div>
         <div class="col">
           <div class="card h-100 w-100"  @click="doctorlibrary">
             <img class="icon-img1" src="~@/assets/img/medical-knowledge.png" />

             <div class="card-body">
               <h5 class="card-title">Clinical resources</h5>
               <p class="card-text">Refer treatment workflows, guidelines, manual of disorders, etc.</p>
             </div>
           </div>
         </div>
         <div class="col">
           
           <div class="card h-100 w-100" >
             <div class="container">
         <span class="comming-soon-text" v-bind:class="[showGrowYourPractice||showGrowYourPractice ? 'hideImg' : '']">COMING SOON</span>
       </div>
             <img class="icon-img1" src="~@/assets/images/foldfile.png" />

             <div class="card-body">
               <h5 class="card-title">Patient handouts, physical and/or digital</h5>
               <p class="card-text">Enhance adherence to your consultation with handouts at the end of consultation</p>
             </div>
           </div>
         </div>
       </div>
     </div>


   </section>
 


</div>

    <div class="container rx-wrapper pl-0 pr-0" style="background: #ffffff" id="public">
      <section class="text-color-white section-space">
        <div class="container-fluid doc-text text-center">
          <div class="costForYouText">
            <h1>What is the cost to you?</h1>
            <div class="col-12 mt-4">
              <h3 class="doctor-text cont">None, if you use the app for your individual practice.</h3>
            </div>
          </div>
        </div>
      </section>
      <section class="text-color-white section-space">
        <div class="container-fluid text-align text-center">
          <h1 class="text-center content">How do we earn?</h1>
          <div class="col-12 mt-3">
            <h3 class="doctor-text cont">
              We charge a nominal amount per team member, if you wish to add a team of doctors to your RxIx account.
            </h3>
          </div>
          <!-- <div class="col-12 mt-3">
            <h3 class="doctor-text cont">
              We charge you a nominal amount per team member.
            </h3>
          </div> -->
        </div>
      </section>
      <div class="container text-align text-center section-space mb-2">
        <div class="connect-text d-none d-lg-block">
          <h1 class="mb-0">Connect with RxIx Help Desk</h1>
        </div>
        <div class="connect-text d-block d-lg-none">
          <h1 class="mb-0">Connect with</h1>
          <h1>RxIx Help Desk</h1>
        </div>
        <div class="container call-whatsapp-content mt-3 mb-3">
          <img class="img-whatsapp-icon" src="@/assets/images/whatsapp-icon.png">
          <h1 class="whatsapp-number">89 40 89 4300</h1>
        </div>
        <div class="d-none d-lg-block mt-4">
          <h3 class="mt-0 mb-0">Need anything to run a</h3>
          <h3 class="mb-0">community-valued practice? Tell us.</h3>
          <h3>We will try to organize the support.</h3>

          <h3 class="mt-4 mb-0">Not a member of RxIx? No problem. You may</h3>
          <h3>still reach out to us.</h3>
        </div>

        <div class="d-block d-lg-none connectwithRxixSubText">
          <h3 class="mb-0">Need anything to run a</h3>
          <h3 class="mb-0">community-valued practice?</h3>
          <h3 class="mb-0">Tell us. We will try to</h3>
          <h3>organize the support.</h3>

          <h3 class="mt-4 mb-0">Not a member of RxIx?</h3>
          <h3 class="mb-0">No problem. You may</h3>
          <h3>still reach out to us.</h3>
        </div>

      </div>

    </div>
  </div>
  <MobileNavbar></MobileNavbar>
</template>
<script>
import MobileNavbar from "../common-mobile-footer.vue"
import { defineComponent } from "vue";
import axios from "axios"
export default defineComponent({
  components: {
    MobileNavbar
  },
  data() {
    return {
      joinData: [],
      practiceGroupCount: '',
      showGrowYourPractice: false,
      showInformedConsultation: false,
    };
  },

  created: function () {
    this.patientsupAdd()
  },
  methods: {
    patientgroupDoctor(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor/patientgroups');
    },
    receiveconsultation(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor/consultationrequests');
    },
    doctorcommunities(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor/communities');
    },
    offerconsultation(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor/offerconsultation');
    },
    doctorlibrary(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor/clinicalresources');
    },
    informationConsultation() {
      this.showInformedConsultation = true
    },
    yourPractice() {
      this.showGrowYourPractice = true;
    },
    closeYourPractice(){
this.showGrowYourPractice=false
    },
    closeInformationConsultation(){
      this.showInformedConsultation = false
    },
    async patientsupAdd() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/patient-support/add`)
        .then((response) => {
          if (response?.data) {
            response?.data.map((data) => {
              if (data.status == true) {
                this.joinData.push(data)
                this.practiceGroupCount = this.joinData.length
              }
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
    }

  },
  name: "HomePageB",
  props: {},
});
</script>
<style>
.hideImg{
  z-index: -1;
}
.popupbox{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 6px
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 
}
   .bottom-link-navbar{
    display: block !important;
  }
  li.nav-item.nav {
    display: block !important;
  }

.section-space{
  margin-top: 5rem;
}

fieldset.RxixSupportToDoctor legend {
  background: #fff;
  padding: 4px 10px;
  /* color: #000; */
  margin: 0 auto;
  display: block;
  float: none;
  width: auto;
  font-size: 32px;
}

div.connect-text {
  color: #000;
}

.call-whatsapp-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-whatsapp-icon {
  height: 75px;
  width: 75px;
  margin-right: 1rem;
}

span.comming-soon-text {
  font-size: 25px;
  background-color: #cd381c;
  color: #ffff;
  margin-top: 0;
  position: relative;
  bottom: 17px;
  padding: 10px;
  box-shadow: 2px 1px 6px 1px rgba(50, 50, 50, 0.75);
}


.doctorPageFirstSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-group.mt-5.pad {
  width: 100%;
}

.cards-banner-doctor {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #e5f4f5;
  background-image: url("~@/assets/images/bg-top.svg");
  background-repeat: no-repeat;
  background-size: 110%;
  /* padding-top: 130px; */
}


.text-center-cards {
  text-align: center;
  margin-top: 6rem;
  color: #000;
}

.btn-box {
  border-color: #34989f !important;
}



.img-carousel-doctor {
  width: 300px;
  height: 250px;
}

.nav-tabs {
  border-bottom: 0px !important;
}


.RxixSupportToDoctor {
  display: flex;
  justify-content: space-between;
  border: 2px solid #00979e;
  border-radius: 50px;
  padding: 20px;
}


.heading.text-space {
  margin-bottom: 0px;
  margin-top: 1.5rem;
}


.text-center.content {
  color: #000;
}

.costForYouText h1 {
  color: #000;
}

div.doctorfirstSectionIamge img.img-doctor {
  width: 25vw;
  height: 28vw;
  margin-top: 20px;
}


div.doctor-firstSectionText.mt-4 h1 {
  color: #2c3e50;
  margin-bottom: 0;
  font-size: 2vw;
}

.line-txt {
  margin-top: 3vw;
}



.text-center.mt-5 {
  color: black;
}


section.banner-two2.img.curve {
  background-repeat: no-repeat;
  background-size: cover;

  background-image: url("~@/assets/images/better-consultation-banner2.jpg");
  background-size: 100% 100%;
  margin-top: 4.5rem;
}


.rxix-menu li.nav-item a.nav-link {
  color: #00979e;
}

@media screen and (max-width: 990px) {
  hr.border-line {
    width: 65%;
    margin-left: 20%;
    border: 1px solid #00979e;
    margin-bottom: 30px;
  }
}

button.accordion-button.collapsed.text-color.border-color {
  background-color: #48adb1;
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #48adb1;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion.box-shadow.mobile-margin {
  width: 70%;
  margin-left: 15%;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .accordion.box-shadow.mobile-margin {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}

.card-body {
    flex: 1 1 auto;
    min-height: 72px;
    padding: 20px;
    background: #fff;
}

.card-body.card-active {
  margin-top: 2.3rem;
}
.banner-two3 {
        /* padding-top: 50px !important; */
        padding-bottom: 30px !important;
    }


.btn.btn-blue-color.text-white.btn-submit {
  float: right;
}

img.icon-img1 {
  width: 80px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}





.card-title-mt-3 {
  margin-top: 1.3rem !important;
  width: 100%;
  height: 45px;
}

.div-rxix {
  text-align: center;
  color: #000;

}

.join-group.mt-5 {

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}



.card {
  border-radius: 10px;
  cursor: pointer;
  width: 18rem;
  border-color: #a0d4d8;
  border-width: medium;
  position: initial;
  box-shadow: 
  8px -8px 12px 0 rgba(0, 0, 0, 0.1),
    -12px 12px 16px rgba(255, 255, 255, 0.25);
}
.card-item {
  background-color: #fff;
  border-radius: 10px;
  width: 18rem;
  border: 2px solid #a0d4d8 ;
  border-width: medium;
  position: initial;
}
.card-body{
border-radius: 10px;
}

.card-body h5 {
    font-weight: 600 !important;
}
@media only screen and (max-width: 1200px) {
  .img-carousel-doctor {
    width: 250px;
    height: 200px;
  }
}

@media only screen and (max-width: 992px) {
  .carousel-inner {
    width: min-content;
    height: 495px;
    margin: auto;
  }
  .mobileborder{
    border: 2px solid #00979e;
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;
  }
  .carousel-indicators [data-bs-target] {
    background-color: #00979e;
    opacity: 0.2;
  }
  .carousel-indicators .active {
    opacity: 1;
  }
  .carousel-indicators {
    position: unset;
    border-radius: 50%;
  }
 
  .img-whatsapp-icon {
    height: 50px;
    width: 50px;
    margin-right: 1rem;
  }

  .line-txt {
    margin-top: 20px;
  }

  .costForYouText h1 {
    font-size: 28px;
  }

  .text-center.content {
    font-size: 28px;
  }

  .div-rxix h1 {
    font-size: 28px;
  }

  .text-center-cards h1 {
    font-size: 28px;
  }

  div.connect-text h1 {
    font-size: 28px;
  }

  .connectwithRxixSubText h3 {
    font-size: 25px;
  }

  h3.doctor-text {
    font-size: 25px;
  }

  fieldset.RxixSupportToDoctor legend {
    font-size: 27px;
  }

  div.doctorfirstSectionIamge img.img-doctor {
    width: 35vw;
    height: 37vw;
  }



  div.sliderMobileView {
    margin-top: 1rem;
  }

  .join-group.mt-5 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }

  div.col-4.mobileViewCenter {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {
  .questions-icon{
   font-size: 30px; 
   color: #fff;
  }
  .whatsapp-number {
    font-size: 28px;
  }

  .navbarmobileFix {
    margin-bottom: 7rem;
  }

  div.doctor-firstSectionText.mt-4 h1 {
    color: #fff;
    font-size: 30px;
  }

  .doctorPageFirstSection {
    background-color: #00979e;
  }

  section.banner-two2.img.curve {
    background-repeat: no-repeat;
    background-size: cover;

    background-image: url("~@/assets/images/better-consultation-banner2.jpg");
    background-size: 100% 100%;
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) {
  .text-center-cards {
  text-align: center;
  margin-top: 1rem;
  color: #000;
}
}

@media screen and (max-width: 704px) {
  .heading.text-space {
    margin-top: 1rem;
  }

}

@media screen and (max-width:576px) {
  .features .col {
        margin-top: 20px !important;
    }
  .carousel-inner {
    height: 370px;
  }
  .text-center-cards {
  text-align: center;
  margin-top: 1rem;
  color: #000;
}
  .questions-icon{
   font-size: 25px; 
  }
  .mobileborder{
    margin-top: 10px;
  }
  .popupbox {
    padding: 10px;
  }

  .whatsapp-number {
    font-size: 23px;
  }

  .line-txt {
    margin-top: 15px;
  }

  div.doctor-firstSectionText.mt-4 h1 {
    font-size: 25px;
  }

  .costForYouText h1 {
    font-size: 23px;
  }

  .text-center.content {
    font-size: 23px;
  }

  .div-rxix h1 {
    font-size: 23px;
  }

  .text-center-cards h1 {
    font-size: 23px;
  }

  div.connect-text h1 {
    font-size: 23px;
  }

  .connectwithRxixSubText h3 {
    font-size: 20px;
  }

  h3.doctor-text {
    font-size: 20px;
  }




  

  span.comming-soon-text {
    font-size: 20px;
  }

  .card {
    width: 15rem;
  }
  .card-item {
    width: 15rem;
  }

 

  fieldset.RxixSupportToDoctor legend {
    font-size: 20px;
  }

  

  .img-carousel-doctor {
    width: 220px;
    height: 180px;
  }
}
</style>
